// src/components/Navbar.js
import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import logo from '../assets/logo.png'; // Import the logo image
import './Navbar.css'; // Import custom CSS for styling
import { useNavigate } from 'react-router-dom';

const NavbarComponent = () => {
  const navigate = useNavigate();

  const handleContactUsClick = () => {
    navigate('/');
    setTimeout(() => {
      document.getElementById('contact-us').scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return (
    <Navbar bg="black" variant="dark" expand="lg" className="shadow-sm">
      <Container>
        <Navbar.Brand as={Link} to="/" className="brand">
          <img
            src={logo} // Use the imported logo variable
            alt="Aarvath Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="d-flex">
          <Nav className="navbar-nav">
            <Nav.Link as={Link} to="/home">Home</Nav.Link>
            <Nav.Link as={Link} to="/about">About Us</Nav.Link>
            <Nav.Link as={Link} to="/achievements">Achievements</Nav.Link>
            <Nav.Link className='nav-link' onClick={handleContactUsClick} style={{ cursor: 'pointer' }}>
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
