// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarComponent from './components/Navbar';
import LandingPage from './pages/LandingPage'; // Use LandingPage for both routes
import Achievements from './pages/Achievements';
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  return (
    <Router>
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<LandingPage />} /> {/* Landing Page */}
        <Route path="/home" element={<LandingPage />} /> {/* Home Page */}
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/about" element={<Achievements />} />
        {/* Add other routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
