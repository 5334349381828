import React from 'react';
import './Achievements.css';

const Achievements = () => {
  return (
    <div className="achievements-container">
        <h1 className="achievements-title">Our Story</h1>
      <section className="our-story-section">
        <p className="our-story-description">
          Our journey began with a vision to revolutionize the transportation industry. 
          Through dedication and innovation, we have developed cutting-edge solar-powered vehicles 
          that contribute to a sustainable future. From our humble beginnings, we have grown into a leader 
          in solar technology, continually pushing the boundaries to provide eco-friendly transportation solutions.
        </p>
      </section>

      {/* Achievement Sections */}
      <h1 className="achievements-title">Our Achievements</h1>
      <section className="achievement-section">
        <h2 className="achievement-heading">Achievement 1: 5 Lakhs Seed Funding by Cisco Thingqbator</h2>
        <p className="achievement-description">We secured a significant milestone with a seed funding of 5 Lakhs from Cisco Thingqbator. This funding has been instrumental in accelerating our research and development efforts, allowing us to innovate and expand our capabilities in solar-powered transportation solutions. We are grateful for Cisco Thingqbator's support and are committed to leveraging these resources to drive our mission forward.</p>
      </section>

      <section className="achievement-section">
        <h2 className="achievement-heading">Achievement 2: Top 10 in Manthan 2022-23</h2>
        <p className="achievement-description">We proudly secured a position in the Top 10 at Manthan 2022-23, a prestigious innovation and entrepreneurship competition conducted by the Government of Karnataka. This recognition underscores our commitment to excellence and innovation in the field of solar-powered transportation. Being among the top contenders has fueled our drive to continue pushing boundaries and delivering impactful solutions.</p>
      </section>

      <section className="achievement-section">
        <h2 className="achievement-heading">Achievement 3: 1st in I2CONECT hosted by IEEE</h2>
        <p className="achievement-description">We achieved 1st place in the I2CONECT competition, hosted by IEEE, and were awarded a cash prize of 5,000/-. This victory highlights our innovative approach and technical excellence in the field of solar-powered transportation, showcasing our ability to lead and excel in competitive environments.</p>
      </section>


      {/* Add more achievement sections as needed */}
    </div>
  );
};

export default Achievements;
