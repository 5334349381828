import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import solarCycleImg from '../assets/solarcycle.jpg'; // Import the image
import sahyadriLogo from '../assets/sahyadrilogo.png'; // Import the logo
import inuntyLogo from '../assets/inuntylogo.png'; // Import the logo
import thingqbatorLogo from '../assets/Thingqbatorlogo.png'; // Import the logo
import logoImg from '../assets/logo.jpg'; // Import the footer logo

const LandingPage = () => {
  return (
    <div>
      {/* First Section */}
      <div className="landing-page-container">
        <div className="landing-box">
          <h1 className="landing-title">AARVATH MOBITECH</h1>
          <p className="landing-subtitle">Solar Powered Vehicles</p>
        </div>
        <div className="bottom-box">
          <p className="landing-subtitle">Coming soon...</p>
        </div>
        <div className="background-image"></div>
      </div>

      {/* About Section */}
      <section className="about-section">
        <h2 className="about-head">Our Vision & Mission</h2>
        <div className="about-content">
          <img src={solarCycleImg} alt="Our Team" className="about-image" />
          <div className="text1">
      <p className="red-text">Vision</p>
      <p>Redefine transportation with efficient solar-powered vehicles.</p>
      <p className="red-text">Mission</p>
      <p>Innovate, Develop, Upgrade, Foster, Educate, Engage.</p>
    </div>
        </div>
        <div className="marquee">
  <div className="marquee--inner">
    <span>
      <div className="slide"><img src={sahyadriLogo} alt="Sahyadri Logo" /></div>
      <div className="slide"><img src={inuntyLogo} alt="Inunty Logo" /></div>
      <div className="slide"><img src={thingqbatorLogo} alt="Thingqbator Logo" /></div>
    </span>
    <span>
      <div className="slide"><img src={sahyadriLogo} alt="Sahyadri Logo" /></div>
      <div className="slide"><img src={inuntyLogo} alt="Inunty Logo" /></div>
      <div className="slide"><img src={thingqbatorLogo} alt="Thingqbator Logo" /></div>
    </span>
  </div>
</div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-column">
          <img src={logoImg} alt="Logo" />
        </div>
        <div className="footer-column">
          <h2>About Us</h2>
          <ul>
          <Link to="/achievements"><li>Our Story</li></Link>
            <Link to="/achievements"><li>Achievements</li></Link>
          </ul>
        </div>
        <div className="footer-column">
          <h2>Support</h2>
          <ul>
            <li>Contact Support</li>
            <li>FAQ</li>
          </ul>
        </div>
        <div id="contact-us" className="footer-column">
          <h2 id="contact">Contact Us</h2>
          <form>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" />
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" />
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" />
            <button type="submit">Submit</button>
          </form>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
